import axios from 'axios'

const { VUE_APP_SHIELD_URL } = process.env

export default class SoapService {

    static async consultaSoap(patente, token) {
        try {

            const soapRes = await axios.post(`${VUE_APP_SHIELD_URL}/cartSoap/getconsulta`,
                {
                    patente: patente,
                },
                {
                    headers: {
                        // Se agrega el Header de authorización
                        "x-captcha-authorization": token,
                    },
                }
            );
            return soapRes.data;
        } catch (error) {
            return error
        }
    }
}