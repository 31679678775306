<template>
  <div class="containerStyle">
    <v-container>
      <!-- <div :class="bannerView">BANNER SEGURO OBLIGATORIO SOAP 2024</div> -->
      <div :class="bannerView"></div>
      <br>

      <div v-if="servicesData.e1 != 3 && servicesData.e1 != 2" :class="cartBoxView">
        <v-btn @click="redirectFindSoap()" :class="btnFindSoapView" id="volver_2">CONSULTA TU SOAP</v-btn>
        <CarritoCompra id="home_2" :class="btnCartButtonView" :patentes="arrayShoppingCart" :e1="servicesData.e1">
        </CarritoCompra>
      </div>

      <div v-else :class="cartBoxView">
        <v-btn @click="redirectFindSoap()" :class="btnFindSoapView3" id="volver_5">CONSULTA TU SOAP</v-btn>
      </div>



      <br v-if="screen <= 800">
      <v-stepper alt-labels v-model="servicesData.e1" elevation="0" :class="steps">
        <v-stepper-header :class="stepperHeaderView">

          <v-stepper-step :complete="servicesData.e1 > 1" step="1"><b :class="stepLabelSelected">Revisa tus
              datos</b></v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="servicesData.e1 > 2" step="2" class="stepLabel"><b
              :class="stepLabelSelected">Pagar</b></v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3" class="stepLabel"><b :class="stepLabelSelected">Recibe e imprime tu
              SOAP</b></v-stepper-step>

        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <div v-if="loadSoap" class="text-center">
              <br>
              <br>
              <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>
              Cargando
            </div>

            <div v-else>

              <InitForm v-if="loadSoap == false" :carData="carData" :propietarioData="propietarioData"
                :planData="planData" :servicesData="servicesData" :arrayShoppingCart="arrayShoppingCart" />

            </div>

          </v-stepper-content>

          <v-stepper-content step="2">
            <div v-if="loading" class="text-center">
              <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>
              Cargando
            </div>

            <SecForm v-else :carData="carData" :propietarioData="propietarioData" :planData="planData"
              :servicesData="servicesData" :arrayShoppingCart="arrayShoppingCart" @getToken="getToken" />

          </v-stepper-content>

          <v-stepper-content step="3">
            <div v-if="loading || loadingFinal" class="text-center">
              <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br><br>
              Estamos procesando tu cotización. . .
            </div>

            <ThirdForm v-else-if="!loading && !loadingFinal" />

            <br>

          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

      <br><br>

      <div v-if="servicesData.e1 == 2 && conditions == true && screen > 800" class="finalPayContainerDesktop">

        <div v-if="loadTokenPayment">

          <br>
          <br>

          <div style="display: flex; justify-content: center; align-items: center;">
            <b class="opcionPago"> Puedes pagar tu SOAP con: </b>
          </div>

          <div style="display: flex; justify-content: center; align-items: center;">
            <v-img contain :src="webPayLogo" style="max-width: 300px; height: 135px;" />
          </div>

          <div class="text-center">

            <br>
            <br>
            <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>

          </div>

          <br>
          <br>

          <div style="display: flex; justify-content: center; align-items: center;">
            <v-btn disabled class="button1" id="no_3" @click="btnVolver()">Volver </v-btn>
            <br>
            <br>
            <br>

            <form :action="webPayUrl" method="POST" ref="form_payment">
              <input type="hidden" name="token_ws" :value="token" />
              <v-btn disabled class="button2" key="first" type="submit" value="Pagar" @click="obtenerToken"
                id="irAPagar_3">
                Ir a pagar
              </v-btn>
            </form>
          </div>

          <br>
          <br>

        </div>

        <div v-else>

          <br>
          <br>

          <div style="display: flex; justify-content: center; align-items: center;">
            <b class="opcionPago"> Puedes pagar tu SOAP con: </b>
          </div>

          <div style="display: flex; justify-content: center; align-items: center;">
            <v-img contain :src="webPayLogo" style="max-width: 300px; height: 135px;" />
          </div>

          <div style="display: flex; justify-content: center; align-items: center;">
            <v-btn class="button1" id="no_3" @click="btnVolver()">Volver </v-btn>
            <br>
            <br>
            <br>

            <form :action="webPayUrl" method="POST" ref="form_payment">
              <input type="hidden" name="token_ws" :value="token" />
              <v-btn class="button2" key="first" type="submit" value="Pagar" @click="obtenerToken" id="irAPagar_3">
                Ir a pagar
              </v-btn>
            </form>
          </div>

          <br>
          <br>

        </div>

      </div>

      <div v-if="servicesData.e1 == 2 && conditions == true && screen <= 800" class="finalPayContainerMovil">

        <div v-if="loadTokenPayment">

          <br>
          <br>

          <div style="display: flex; justify-content: center; align-items: center;">
            <b class="opcionPago"> Puedes pagar tu SOAP con: </b>
          </div>

          <div style="display: flex; justify-content: center; align-items: center;">
            <v-img contain :src="webPayLogo" style="max-width: 300px; height: 135px;" />
          </div>

          <div class="text-center">

            <br>
            <br>
            <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>

          </div>

          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <v-btn disabled class="button1Mobile" id="no_3" style=" margin-right: 30px" @click="btnVolver()">Volver
            </v-btn>

            <br>

            <form :action="webPayUrl" method="POST" ref="form_payment">
              <input type="hidden" name="token_ws" :value="token" />
              <v-btn disabled class="button2Mobile" id="irAPagar_3" key="first" type="submit" value="Pagar"
                @click="obtenerToken">
                Ir a pagar
              </v-btn>
            </form>
          </div>

          <br>
          <br>

        </div>

        <div v-else>

          <br>
          <br>

          <div style="display: flex; justify-content: center; align-items: center;">
            <b class="opcionPago"> Puedes pagar tu SOAP con: </b>
          </div>

          <div style="display: flex; justify-content: center; align-items: center;">
            <v-img contain :src="webPayLogo" style="max-width: 300px; height: 135px;" />
          </div>

          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <v-btn class="button1Mobile" id="no_3" style=" margin-right: 30px" @click="btnVolver()">Volver </v-btn>

            <br>

            <form :action="webPayUrl" method="POST" ref="form_payment">
              <input type="hidden" name="token_ws" :value="token" />
              <v-btn class="button2Mobile" id="irAPagar_3" key="first" type="submit" value="Pagar"
                @click="obtenerToken">
                Ir a pagar
              </v-btn>
            </form>
          </div>

          <br>
          <br>

        </div>

      </div>

    </v-container>

  </div>
</template>

<script>
import Cuotas from "@/assets/images/Cuotas.png";
import webPayLogo from "@/assets/images/webpay.png";

import InitForm from "@/components/InitForm.vue";
import SecForm from "@/components/SecForm.vue";
import ThirdForm from "@/components/ThirdForm.vue";
import CarritoCompra from "@/components/Carrito.vue";

import SoapService from "../services/soap.service";
import ShieldService from "../services/shield.service";

import CryptoJS from 'crypto-js';
import swal from 'sweetalert';
import Vue from "vue";
import keygen from 'keygen';

import { VueReCaptcha } from 'vue-recaptcha-v3';
const { VUE_APP_RECAPTCHA3_SITE_KEY } = process.env;

Vue.use(VueReCaptcha, { siteKey: VUE_APP_RECAPTCHA3_SITE_KEY })

export default {
  name: "Form",
  components: {
    InitForm,
    SecForm,
    ThirdForm,
    CarritoCompra
  },
  data() {
    return {
      secretKey: process.env.VUE_APP_CRYPT,

      typePlan: null,
      conditions: null,
      webPayLogo,
      token: null,

      bannerView: null,
      cartBoxView: null,
      btnFindSoapView: null,
      btnCartButtonView: null,
      stepperHeaderView: null,
      stepLabelSelected: null,
      btnFindSoapView3: null,

      Cuotas,
      switch: true,
      generaropt: null,
      loadSoap: true,
      keygen: null,
      steps: null,
      screen: window.screen.width,
      loading: false,
      loadingFinal: false,
      patenteData: {},
      patentes: [],
      patenteUtils: null,
      priceTotal: 0,
      typeCliente: null,

      propietarioData: {
        rut: null,
        nombres: null,
        firtlastname: null,
        secondlastname: null,
        email: null,
        phoneNumber: null,
        propietario: true,
        rutPropietario: null,
        remarque: null,
        pricePuntos: null,
        campana: null,
      },

      arrayShoppingCart: [],

      servicesData: {
        loadSoap: false,
        loadPlans: false,

        keygen: null,
        token: null,
        priceTotal: 0,
        rutPropietario: null,
        e1: 1,
        typePlan: null,
        typeCliente: null,
      },

      carData: {
        id: null,
        patente: null,
        patenteDv: null,
        year: null,
        type: null,
        marca: null,
        model: null,
        motor: null,
        price: null,
        externalProductId: null,
      },

      planData: {},

      planAuto: null,
      planCamioneta: null,
      planMoto: null,
      planArrastre: null,
      planBus: null,
      plans: {},
      planId: null,
      loadTokenPayment: false,
      loadPlans: true,
      webPayUrl: process.env.VUE_APP_WEBPAY_URL,
    };
  },
  async mounted() {

    let path;
    let cliente;

    if (this.$route.path.includes('/no-clientes')) {
      path = 'soap-no-clientes-scotia';
      cliente = 'no-clientes';
    } else if (this.$route.path.includes('/clientes')) {
      path = 'soap-clientes-scotia';
      cliente = 'clientes';
    } else if (this.$route.path.includes('/colaboradores')) {
      path = 'soap-colaboradores-scotia';
      cliente = 'colaboradores';
    } else if (this.$route.path.includes('/pyme')) {
      path = 'soap-pyme-scotia';
      cliente = 'pyme';
    }

    const arrayShoppingCartAux = localStorage.getItem('arrayShoppingCart');
    const propietarioDataAux = localStorage.getItem('propietarioData');
    const servicesDataAux = localStorage.getItem('servicesData');
    const carDataAux = localStorage.getItem('carData');
    const planDataAux = localStorage.getItem('planData');

    if (arrayShoppingCartAux) {
      const bytesArrayShoppingCart = CryptoJS.AES.decrypt(arrayShoppingCartAux, this.secretKey);
      const decryptedArrayShoppingCart = bytesArrayShoppingCart.toString(CryptoJS.enc.Utf8);
      this.arrayShoppingCart = JSON.parse(decryptedArrayShoppingCart);
      console.log("arrayShoppingCart: ", this.arrayShoppingCart);

    }

    if (propietarioDataAux) {
      const bytesPropietarioData = CryptoJS.AES.decrypt(propietarioDataAux, this.secretKey);
      const decryptedPropietarioData = bytesPropietarioData.toString(CryptoJS.enc.Utf8);
      this.propietarioData = JSON.parse(decryptedPropietarioData);
      console.log("propietarioData: ", this.propietarioData);

    }

    if (servicesDataAux) {
      const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
      const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
      this.servicesData = JSON.parse(decryptedServicesData);
      console.log("servicesData: ", this.servicesData);

      if (this.servicesData.typePlan != path) {
        localStorage.removeItem('carData');
        localStorage.removeItem('planData');
        localStorage.removeItem('propietarioData');
        localStorage.removeItem('servicesData');
        localStorage.removeItem('arrayShoppingCart');
        this.$router.push({ path: `/${cliente}/inicio` });
      }

    }

    if (carDataAux) {
      const bytesCarData = CryptoJS.AES.decrypt(carDataAux, this.secretKey);
      const decryptedCarData = bytesCarData.toString(CryptoJS.enc.Utf8);
      this.carData = JSON.parse(decryptedCarData);
      console.log("carData: ", this.carData);

    }

    if (planDataAux) {
      const bytesPlanData = CryptoJS.AES.decrypt(planDataAux, this.secretKey);
      const decryptedPlanData = bytesPlanData.toString(CryptoJS.enc.Utf8);
      this.planData = JSON.parse(decryptedPlanData);
      console.log("planData: ", this.planData);

    }

    this.propietarioData.campana = window.location.href;

    const query = this.$route.query;

    if (query.process == "behind") {
      this.$router.push({ path: '/info' });
    }

    if (this.screen > 800) {
      this.steps = 'steps'
      this.bannerView = 'bannerView'
      this.cartBoxView = 'cartBoxView'
      this.btnFindSoapView = 'btnFindSoapView'
      this.btnCartButtonView = 'btnCartButtonView'
      this.stepperHeaderView = 'stepperHeaderView'
      this.stepLabelSelected = 'stepLabelSelected'
      this.btnFindSoapView3 = 'btnFindSoapView3'
    } else if (this.screen <= 800) {
      this.steps = 'steps2'
      this.bannerView = 'bannerView2'
      this.cartBoxView = 'cartBoxView2'
      this.btnFindSoapView = 'btnFindSoapView2'
      this.btnCartButtonView = 'btnCartButtonView2'
      this.stepperHeaderView = 'stepperHeaderView2'
      this.stepLabelSelected = 'stepLabelSelected2'
      this.btnFindSoapView3 = 'btnFindSoapView3Mobile'
    }
    if (query) {
      if (query.success) {
        // this.querySuccessState = 'ok'
        this.servicesData.e1 = 3

        return;
      }

      if (this.carData.patente && this.propietarioData.email && this.servicesData.e1 == 1) {

        // reCaptcha v3 token
        await this.$recaptchaLoaded();
        const token = await this.$recaptcha('consultSoap');

        var soapRes = await SoapService.consultaSoap(this.carData.patente, token);

        if (soapRes.data == null) {
          swal({
            title: "Lo sentimos!",
            text: "No existen registros correspondientes a la patente ingresada",
            icon: "info",
            button: "Ok",
          }).then(() => {
            this.$router.push({ path: `/${this.servicesData.typeCliente}/inicio` });
          });
        } else if (soapRes.status == 'ok') {

          this.carData.id = soapRes.data._id;
          this.carData.year = soapRes.data.anio_fabricacion;
          this.carData.patenteDv = soapRes.data.dv_ppu;
          this.carData.type = soapRes.data.tipo_vehiculo;
          this.carData.marca = soapRes.data.marca;
          this.carData.model = soapRes.data.modelo;
          this.carData.motor = soapRes.data.numero_motor;

          this.propietarioData.rut = soapRes.data.rut + soapRes.data.dv;

          var nombreCompleto = soapRes.data.nombre_razonsocial.split(' ');

          this.propietarioData.nombres = nombreCompleto[3] ? nombreCompleto[2] + ' ' + nombreCompleto[3] : nombreCompleto[2];
          this.propietarioData.firtlastname = nombreCompleto[0];
          this.propietarioData.secondlastname = nombreCompleto[1];
          this.loadSoap = false;

        }

        switch (this.carData.type) {
          case 'AUTOMOVIL':
          case 'JEEP':
          case 'STATION WAGON':
          case 'LIMUSINA':
            this.carData.price = this.planData.soap.car.price;
            this.propietarioData.remarque = this.planData.soap.car.remarque;
            this.carData.externalProductId = this.planData.soap.car.externalProductId;
            break;

          case 'CAMIONETA':
          case 'FURGON':
            this.carData.price = this.planData.soap.camioneta.price;
            this.propietarioData.remarque = this.planData.soap.camioneta.remarque;
            this.carData.externalProductId = this.planData.soap.camioneta.externalProductId;
            break;

          case 'MOTO':
          case 'CUATRIMOTO':
          case 'MOTONETA':
            this.carData.price = this.planData.soap.moto.price;
            this.propietarioData.remarque = this.planData.soap.moto.remarque;
            this.carData.externalProductId = this.planData.soap.moto.externalProductId;
            break;

          case 'CARRO DE ARRASTRE':
          case 'CASA RODANTE MOTORIZADA':
            this.carData.price = this.planData.soap.arrastre.price;
            this.propietarioData.remarque = this.planData.soap.arrastre.remarque;
            this.carData.externalProductId = this.planData.soap.arrastre.externalProductId;
            break;

          case 'MINIBUS':
            this.carData.price = this.planData.soap.bus.price;
            this.propietarioData.remarque = this.planData.soap.bus.remarque;
            this.carData.externalProductId = this.planData.soap.bus.externalProductId;
            break;

          default:
            swal({
              title: "¡Tipo de vehículo no válido!",
              icon: "info",
              button: "Ok",
            }).then(() => {
              this.$router.push({ path: `/${this.servicesData.typeCliente}/inicio` });
            });
            break;
        }

        try {

          // reCaptcha v3 token
          await this.$recaptchaLoaded();
          const tokenConsulta = await this.$recaptcha('consultaSoap');

          await ShieldService.createSoapConsulta(this.propietarioData, this.carData, tokenConsulta);

        } catch (error) {
          console.log(error);
        }

      }
    }
  },
  methods: {
    async obtenerToken() {

      event.preventDefault();

      // reCaptcha v3 token
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha('createSoap');

      this.loadTokenPayment = true;

      const currentDate = new Date();
      const currentHour = ('0' + currentDate.getHours()).slice(-2);
      const currentMinutes = ('0' + currentDate.getMinutes()).slice(-2);
      const currentDay = ('0' + currentDate.getDate()).slice(-2);
      const currentMonth = ('0' + (currentDate.getMonth() + 1)).slice(-2);

      const time = currentHour + ':' + currentMinutes;

      this.servicesData.keygen = keygen.url(keygen.large) + `-${currentDay}/${currentMonth}-${time}`;

      const servicesDataLocal = JSON.stringify(this.servicesData);
      const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
      localStorage.setItem('servicesData', encryptedServicesDataLocal);

      var webPayToken = await ShieldService.create(this.arrayShoppingCart, this.servicesData, this.planData, token);
      if (webPayToken.status == 'ok') {
        this.token = webPayToken.data.token;
        setTimeout(() => {
          this.submitPago()
        }, 3000);
      } else {
        swal({
          title: "UPS! Algo salió mal.",
          text: "Vuelva a intentarlo nuevamente.",
          icon: "info",
          button: "Ok",
        }).then(() => {
          localStorage.removeItem('carData');
          localStorage.removeItem('planData');
          localStorage.removeItem('propietarioData');
          localStorage.removeItem('servicesData');
          localStorage.removeItem('arrayShoppingCart');
          this.$router.push({ path: `/${this.servicesData.typeCliente}/inicio` });
        });
      }
    },
    async submitPago() {
      if (this.token && this.conditions && this.servicesData.rutPropietario) {
        this.$refs.form_payment.submit();
        return;
      } else {
        swal({
          icon: 'info',
          title: '¡Precaución!',
          text: 'Debes autorizar el tratamiento de tus datos personales e ingresar rut de quién realizará el pago.',
          timer: 2000,
          buttons: false,
        });
      }
    },
    async redirectFindSoap() {
      const consulta_1 = document.getElementById('consulta_1');
      const consulta_5 = document.getElementById('consulta_5');
      this.$router.push({ path: `/consulta` });
    },
    btnVolver() {
      this.$router.push({ path: `/${this.servicesData.typeCliente}/inicio` });
    },
    getToken(elementos) {
      // this.token = elementos.token;
      // this.propietarioData.rutPropietario = elementos.rut;
      this.conditions = elementos.conditions;
    },
  },
};
</script>

<style lang="scss">
.theme--light.v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step {
  background: #91A6E4;
  border-radius: 50%;
  overflow: hidden;
  font-size: 18px;
  font-weight: bold;
  /* Evitar que el contenido se visualice fuera del borde*/
}

.theme--light.v-stepper .v-stepper__label {
  text-align: center;
  font: Arial;
  font-size: 15px;
  letter-spacing: 0.23px;
  color: #262626;
  opacity: 1;
  font-size: 15px;
  width: 208px;
}

.v-input .v-label {
  text-align: left;
  font-size: 12px;
  font: Arial;
  letter-spacing: 0.18px;
  color: #2D387C;
  opacity: 1;
}

span.v-stepper__step__step.primary {
  width: 24px;
  height: 24px;
  opacity: 1;
  background: #4F74E3 !important;
  font-size: 18px;
  font-weight: bold;
}

.steps {
  width: 100%;
  margin-top: 5%;
}

.steps2 {
  min-width: 100%;
}

.containerStyle {
  background-color: #FFF;
  height: 80%;
  margin-bottom: 6.5%;
}

.containerStyle2 {
  background-color: #FFF;
  height: 80%;
  margin-bottom: 6%;
}

.bannerView {
  font-weight: bold;
  text-align: center;
  margin-top: -12%;
  font-size: 46px;
  color: #FFFFFF;
}

.bannerView2 {
  font-weight: bold;
  text-align: center;
  margin-top: -42%;
  font-size: 28px;
  color: #FFFFFF;
  margin-bottom: 9%;
}

.stepLabelSelected {
  font-size: 16px;
  font-weight: normal;
}

.stepLabelSelected2 {
  font-size: 12px;
  font-weight: normal;
  width: 80%;
}

.stepLabel {
  font-size: 20px;
}

.btnFindSoapView {
  width: 180px;
  height: 36px !important;
  background: #1D9C6F 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  text-align: center;
  font: normal normal normal 22px/27px Arial;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  margin-left: 42%;
  // margin-top: 2%;
  margin-bottom: -10.99%;
}

.btnFindSoapView2 {
  width: 200px;
  height: 36px !important;
  background: #1D9C6F 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  text-align: center;
  font: normal normal normal 22px/27px Arial;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  // margin-left: -105%;
  // margin-bottom: -49%;
}



.btnFindSoapView3 {
  width: 180px;
  height: 36px !important;
  background: #1D9C6F 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  text-align: center;
  font: normal normal normal 22px/27px Arial;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  margin-left: 70%;
  margin-top: 5%;
  // margin-bottom: -10.99%;
}

.btnFindSoapView3Mobile {
  width: 200px;
  height: 36px !important;
  background: #1D9C6F 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  // margin-left: -107%;
  // margin-top: 20%;
  font-size: 14px !important;
  // margin-bottom: -22%;
  margin-left: -1%;
}

.cart {
  margin-left: 58%;
  margin-bottom: 1%;
}

.cartBoxView {
  margin-top: 10%;
  margin-left: 40%;
  margin-bottom: -4.6%;
}

.cartBoxView2 {
  margin-left: 11%;
  margin-top: 32%;
  display: flex;
  width: 80%;
  margin-bottom: -2%;
}

.finalPayContainer {
  background-color: #F3F3F3;
  width: 116.5%;
  margin-left: -8%;
  margin-right: auto;
  margin-bottom: -9%;
}

.btnCartButtonView2 {
  margin-left: 12%;
  margin-top: -3.9%;
}

.finalPayContainerMovil {
  background-color: #F3F3F3;
  width: 116.5%;
  margin-left: -8%;
  margin-right: auto;
  margin-bottom: -11%;
}

.finalPayContainerDesktop {
  background-color: #F3F3F3;
  width: 140%;
  margin-left: -19%;
  margin-bottom: -107px;
}

.box {
  display: flex;
  margin-bottom: -25%;
}

.boxMovile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -38%;
}

.left {
  padding: 10px;
  width: 40%;
  display: flex;
  justify-content: center;

}

.right {
  padding: 10px;
  width: 90%;
  // background-color: yellowgreen;
}

.images {
  margin-top: 6%;
  margin-bottom: 6%;
}

.imagesMobile {
  margin-top: 6%;
  margin-bottom: 6%;
  width: 95%;
  margin-left: 3%;
}

.payBoxtitle {
  width: 750px;
  height: 29px;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 30px;
  letter-spacing: -0.36px;
  color: #2D387C;
  text-transform: uppercase;
  opacity: 1;
}

.payBoxtitleMovileBox {
  margin-left: 2%;
}

.payBoxtitleMovile {
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  color: #2D387C;
  text-transform: uppercase;
  opacity: 1;
  font-size: 20px;
}

.imagesContainerMovile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin-left: 16.5%;
}

.imagesContainerMovile>div {
  min-width: 70%;
  background-color: #EB4949;
  flex-grow: 1;
  padding: 10px;
  text-align: center;
  margin-right: 5%;
  height: 80px;
  display: flex;
  align-items: center;
}

.btnKnowMoreMovile {
  width: 250px;
  height: 50px !important;
  flex-shrink: 0;
  color: white !important;
  font-weight: bold;
  font-size: 16px !important;
  letter-spacing: 0.8px;

}

.btnKnowMore {
  background-color: #4F74E3 !important;
  color: #FFF !important;
  border-radius: 8px;
  font-weight: bold;
  letter-spacing: 0.8px;
  height: 20% !important;
  width: 22%;
  font-size: 16px !important;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0.8px;
}

.imagesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagesContainer>div {
  background-color: #EB4949;
  flex-grow: 1;
  padding: 10px;
  text-align: center;
  margin-right: 5%;
  height: 80px;
  display: flex;
  align-items: center;
}

.stepperHeaderView {
  box-shadow: none;
  margin: auto;
  width: 75%;
  margin-bottom: -2%;
}

.stepperHeaderView2 {
  box-shadow: none;
  margin: auto;
  width: 100%;
  margin-bottom: -8%;
}

.opcionPago {
  color: var(--AD-Primario-1, #2D387C);
  font-family: 'Helvetica';
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.24px;
  text-transform: uppercase;
}

.button1 {
  width: 270px;
  height: 50px !important;
  background-color: #2D387C !important;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font-size: 18px !important;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  margin-left: -7%;
  font-weight: bold;
}

.button1Mobile {
  width: 270px;
  height: 50px !important;
  background-color: #2D387C !important;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font-size: 18px !important;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  margin-left: 5%;
  font-weight: bold;
  margin-bottom: 2%;
}

.button2 {
  width: 270px;
  height: 50px !important;
  background-color: #4F74E3 !important;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font-size: 18px !important;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  margin-left: 35%;
  font-weight: bold;
}

.button2Mobile {
  width: 270px;
  height: 50px !important;
  background-color: #4F74E3 !important;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font-size: 18px !important;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  margin-left: -2%;
  font-weight: bold;
}

.v-stepper:not(.v-stepper--vertical) .v-stepper__label {
  display: contents !important;
}
</style>