<template>
  <div>

    <v-form v-if="!loadCarCreate" ref="form" v-model="valid" :class="formBox">

      <v-row justify="center">
        <v-dialog v-model="dialog" width="600px">
          <v-card>
            <v-card-title style="background-color:#2D387C ; color: white;">
              <span :class="termsConditionsTitle">TÉRMINOS Y CONDICIONES</span>
            </v-card-title>
            <v-card-text style="text-align: justify;">
              <br>
              <h2>TÉRMINOS Y CONDICIONES DEL SOAP ELECTRÓNICO SOUTHBRIDGE COMPAÑÍA DE SEGUROS GENERALES S.A.</h2>
              <br>
              La compañía que cubre el riesgo es Southbridge Compañía de Seguros Generales S.A. El
              detalle de las condiciones y exclusiones están contenidas en la póliza incorporada al
              depósito de pólizas de la CMF bajo el código POL 3 2013 0487 y en las condiciones
              particulares del contrato. La contratación de este seguro cumple con la Circular 1.864 del
              21.01.08 de la CMF., que regula la venta del SOAP por Internet. Los datos o antecedentes
              ingresados son de exclusiva responsabilidad del contratante asumiendo las consecuencias
              derivadas de errores o inexactitudes de información.
              <br><br><br>
              <h2>Veracidad de la Información</h2>
              <br>
              Los datos o antecedentes ingresados tanto del vehículo como del propietario son de
              exclusiva responsabilidad del solicitante y debe concordar con los que se registran en el
              Registro Nacional de Vehículos Motorizados, los cuales puede obtener del padrón o
              certificado de dominio del vehículo. Cualquier error o inexactitudes de información será
              exclusiva responsabilidad del solicitante, liberando a la compañía de toda responsabilidad.
              <br><br><br>
              <h2>Devolución o Anulaciones</h2>
              <br>
              El seguro SOAP ELECTRÓNICO no permite devoluciones ni anulaciones.
              <br><br><br>
              <h2>Forma de Pago</h2>
              <br>
              Southbridge Compañía de Seguros Generales S.A. no tiene ningún convenio con los Bancos
              para el pago en cuotas de pólizas de seguro obligatorio. La compañía no se hace
              responsable por los intereses que su banco le pueda cobrar al ofrecerle el pago en cuotas.
              Infórmese sobre los intereses que le cobra su banco, comunicándose directamente con su
              ejecutivo.
              <br><br><br>
              <h2>Información de los Vehículos</h2>
              <br>
              Según la normativa vigente, los datos del vehículo no pueden ser modificados a través de
              este medio, en caso de que estos no correspondan o tenga dudas respecto de la exactitud
              de ellos comuníquese a nuestro centro de nuestro call center 800 200 802 (02 2826 8000,
              desde móviles). o acérquese a nuestra casa matriz ubicada en Presidente Riesco 5335 of
              15, Las Condes, Santiago.
              <br><br><br>
              <h2>Despacho a Domicilio</h2>
              <br>
              Por normativa de la CMF, circular Nº 1864, si el contratante así lo desea, y así lo expresa al
              momento de la contratación, Southbridge Compañía de Seguros Generales S.A. deberá
              enviar el certificado SOAP a su domicilio, el que deberá ser despachado por un servicio de
              correo fehaciente y expedito dentro de los 3 días hábiles siguientes a cursada la solicitud. El
              despacho a domicilio tiene un recargo de $5.000 pesos en la Región Metropolitana y de
              $8.000 en otras regiones. El certificado que recibirá por correo postal es una impresión del
              mismo SOAP electrónico, tal como la que el cliente puede imprimir con una impresora
              particular común. Se enviarán todos los SOAP a un mismo domicilio.
              <br><br><br>
              <h2>Consultas</h2>
              <br>
              Si tiene dudas o necesita apoyo en su proceso de contratación del seguro llámenos a
              nuestro centro de soporte Web al 800 200 802 (02 2826 8000 desde móviles). El horario de
              atención es: de lunes a viernes: de 09:00 hrs. a 18:00 hrs.
            </v-card-text>
            <v-card-actions>
              <v-btn :class="btnClose" elevation="0" text @click="dialog = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <br>

      <!-- INICIO WEB -->
      <div v-if="screen > 800">
        <v-container :class="recuadro">

          <v-col class="rectangulo">
            <div class="titles text-center">
              <v-img class="icon" contain :src="Auto" max-width="40" />
              <b class="title1">Datos del</b>
              <b class="title2">vehículo</b>
            </div>
            <br>
            <div class="subtitulo2Box">
              <label class="subtitulo2">Los datos del vehículo se cargarán automáticamente, de no ser así, complételos.
                Todos los datos son obligatorios.</label>
            </div>
            <br>
            <br>


            <v-col class="columnas">

              <div>
                <label class="labelName">Número patente:</label>
                <v-text-field :disabled="carData.patente ? 'disabled' : ''" class="labelsSup" v-model="carData.patente"
                  label="Número patente: " :rules="patenteRules" maxlength="6" minlength="5" required filled solo>
                </v-text-field>
              </div>

              <div>
                <label class="labelName2">Año del vehículo:</label>
                <v-text-field :disabled="carData.year ? 'disabled' : ''" class="labelsInf" v-model="carData.year"
                  label="Año del vehículo: " :rules="yearRules" required filled solo>
                </v-text-field>
              </div>

            </v-col>

            <v-col class="columnas">

              <div>
                <label class="labelName">Tipo de vehículo:</label>
                <v-text-field :disabled="carData.type ? 'disabled' : ''" class="labelsSup" v-model="carData.type"
                  label="Tipo de vehículo: " :rules="typeRules" required filled solo return-object>
                </v-text-field>
              </div>

              <div>
                <label class="labelName2">Marca de vehículo:</label>
                <v-text-field :disabled="carData.marca ? 'disabled' : ''" class="labelsInf" v-model="carData.marca"
                  label="Marca de vehículo: " :rules="marcaRules" required filled solo return-object>
                </v-text-field>
              </div>

            </v-col>

            <v-col class="columnas columnaFinal">

              <div>
                <label class="labelName">Modelo de vehículo:</label>
                <v-text-field :disabled="carData.model ? 'disabled' : ''" class="labelsSup" v-model="carData.model"
                  label="Modelo de vehículo: " :rules="modeloRules" required filled solo return-object>
                </v-text-field>
              </div>

              <div v-if="!campoMotorNull">
                <label class="labelName2">Número de motor:</label>
                <v-text-field disabled class="labelsInf" v-model="carData.motor" label="Número de motor: "
                  :rules="motorRules" required filled solo>
                </v-text-field>
              </div>

              <div v-if="campoMotorNull">
                <label class="labelName2">Número de motor:</label>
                <v-text-field class="labelsInf" v-model="carData.motor" label="Número de motor: " :rules="motorRules"
                  required filled solo>
                </v-text-field>
              </div>
            </v-col>

          </v-col>

          <br>

          <v-col class="rectangulo">
            <div class="titles">
              <v-img class="icon" contain :src="Propietario" max-width="40" />
              <b class="title1">Datos del</b>
              <b class="title2">propietario</b>
            </div>
            <br>
            <div class="subtitulo2Box2">
              <label class="subtitulo2">
                Los datos del propietario se cargarán automáticamente, de no ser así, complételos. Todos los datos son
                obligatorios.
              </label>
            </div>
            <br>
            <br>

            <v-col class="columnas">

              <div>
                <label class="labelName">Rut:</label>
                <v-text-field v-model.lazy="newRut" label="RUT" required filled solo class="labelsSup" :rules="rutRules"
                  @change="changeRut" hide-details="auto">
                </v-text-field>
              </div>

              <div>
                <label class="labelName2">Nombres:</label>
                <v-text-field v-model="propietarioData.nombres" label="Nombres" class="labelsInf" :rules="namesRules"
                  required filled solo>
                </v-text-field>
              </div>

            </v-col>

            <v-col class="columnas">

              <div>
                <label class="labelName">Primer apellido:</label>
                <v-text-field v-model="propietarioData.firtlastname" class="labelsSup" label="Primer apellido: "
                  :rules="surnamePRules" required filled solo>
                </v-text-field>
              </div>

              <div>
                <label class="labelName2">Segundo apellido:</label>
                <v-text-field v-model="propietarioData.secondlastname" class="labelsInf" label="Segundo apellido: "
                  :rules="surnameMRules" required filled solo>
                </v-text-field>
              </div>

            </v-col>

            <v-col class="columnas">

              <div>
                <label class="labelName">Correo electrónico:</label>
                <v-text-field v-model="propietarioData.email" :rules="emailRules" @change="emailValidate()"
                  class="labelsSup" label="Correo electrónico: " required filled solo>
                </v-text-field>
              </div>

              <div>
                <label class="labelName2">Número celular:</label>
                <v-text-field v-model="propietarioData.phoneNumber" class="labelsInf" :rules="phoneRules" :counter="8"
                  maxlength="8" prefix="+569" required filled solo>
                </v-text-field>
              </div>

            </v-col>

          </v-col>
        </v-container>
      </div>
      <!-- FIN WEB -->


      <!-- INICIO MOVIL -->
      <div v-else>
        <v-container :class="recuadro">

          <v-col class="rectangulo">

            <div class="titlesMobile text-center">
              <v-img class="icon" contain :src="Auto" max-width="40" />
              <b class="title1">Datos del</b>
              <b class="title3">vehículo</b>
            </div>
            <br>
            <div class="subtitulo2MobileBox">
              <label class="subtitulo3">
                Los datos del vehículo se cargarán automáticamente, de no ser así, complételos. Todos los datos son
                obligatorios.
              </label>
            </div>
            <br>
            <br>

            <div>
              <label class="labelNameMobile">Número patente:</label>
              <v-text-field :disabled="carData.patente ? 'disabled' : ''" :class="labelsSup" v-model="carData.patente"
                label="Número patente: " :rules="patenteRules" maxlength="6" minlength="5" required solo>
              </v-text-field>
            </div>

            <div>
              <label class="labelNameMobile">Año del vehículo:</label>
              <v-text-field :disabled="carData.year ? 'disabled' : ''" :class="labelsSup" v-model="carData.year"
                label="Año del vehículo: " :rules="yearRules" required solo>
              </v-text-field>
            </div>

            <div>
              <label class="labelNameMobile">Tipo de vehículo:</label>
              <v-text-field :disabled="carData.type ? 'disabled' : ''" :class="labelsSup" v-model="carData.type"
                label="Tipo de vehículo: " :rules="typeRules" required solo return-object>
              </v-text-field>
            </div>

            <div>
              <label class="labelNameMobile">Marca de vehículo:</label>
              <v-text-field :disabled="carData.marca ? 'disabled' : ''" :class="labelsSup" v-model="carData.marca"
                label="Marca de vehículo: " :rules="marcaRules" required solo return-object>
              </v-text-field>
            </div>

            <div>
              <label class="labelNameMobile">Modelo de vehículo:</label>
              <v-text-field :disabled="carData.model ? 'disabled' : ''" :class="labelsSup" v-model="carData.model"
                label="Modelo de vehículo: " :rules="modeloRules" required solo return-object>
              </v-text-field>
            </div>

            <div v-if="!campoMotorNull">
              <label class="labelNameMobile">Número de motor:</label>
              <v-text-field disabled :class="labelsSup" v-model="carData.motor" label="Número de motor: "
                :rules="motorRules" required solo>
              </v-text-field>
              <div class="space"></div>
            </div>

            <div v-if="campoMotorNull">
              <label class="labelNameMobile">Número de motor:</label>
              <v-text-field :class="labelsSup" v-model="carData.motor" label="Número de motor: " :rules="motorRules"
                required solo>
              </v-text-field>
              <div class="space"></div>
            </div>

          </v-col>

          <br>


          <v-col class="rectangulo">

            <div class="titlesMobile text-center">
              <v-img class="icon" contain :src="Propietario" max-width="40" />
              <b class="title1">Datos del</b>
              <b class="title3">propietario</b>
            </div>
            <br>
            <div class="subtitulo2MobileBox">
              <label class="subtitulo3">
                Los datos del propietario se cargarán automáticamente, de no ser así, complételos. Todos los datos son
                obligatorios.
              </label>
            </div>

            <br>
            <br>

            <div>
              <label class="labelNameMobile">Rut:</label>
              <v-text-field v-model.lazy="newRut" label="RUT" required solo :class="labelsSup" :rules="rutRules"
                @change="changeRut" hide-details="auto">
              </v-text-field>
            </div>

            <div>
              <label class="labelNameMobile">Nombres:</label>
              <v-text-field v-model="propietarioData.nombres" label="Nombres" :class="labelsSup" :rules="namesRules"
                required solo>
              </v-text-field>
            </div>

            <div>
              <label class="labelNameMobile">Primer apellido:</label>
              <v-text-field v-model="propietarioData.firtlastname" :class="labelsSup" label="Primer apellido: "
                :rules="surnamePRules" required solo>
              </v-text-field>
            </div>

            <div>
              <label class="labelNameMobile">Segundo apellido:</label>
              <v-text-field v-model="propietarioData.secondlastname" :class="labelsSup" label="Segundo apellido: "
                :rules="surnameMRules" required solo>
              </v-text-field>
            </div>

            <div>
              <label class="labelNameMobile">Correo electrónico:</label>
              <v-text-field v-model="propietarioData.email" :rules="emailRules" @change="emailValidate()"
                :class="labelsSup" label="Correo electrónico: " required solo>
              </v-text-field>
            </div>

            <div>
              <label class="labelNameMobile">Número celular:</label>
              <v-text-field v-model="propietarioData.phoneNumber" :class="labelsSup" :rules="phoneRules" :counter="8"
                prefix="+569" required solo>
              </v-text-field>
              <div class="space"></div>
            </div>
          </v-col>
        </v-container>
      </div>
      <!-- FIN MOVIL -->



      <br>
      <div :class="rectangulo2" class="text-center">

        <br>
        <div :class="boxValue">
          <br>
          <p class="valor"> Valor Cotizado</p>
        </div>
        <br>

        <div :class="priceBox">
          <hr class="hrStyle">
          <b class="precio">{{ formatPrice(carData.price) }}</b>
          <hr class="hrStyle">
        </div>



        <v-btn :class="btnContinue" id="continuar_2" @click="validate()">CONTINUAR</v-btn>

        <br>
        <br>

        <b class="textTerminos">Al continuar estás aceptando nuestros <b><a class="terminosCondiciones" id="terminos_2"
              @click="dialog = true">términos y condiciones.</a></b></b>
      </div>
      <br v-if="screen <= 800">
    </v-form>

    <div v-if="loadCarCreate" class="text-center">
      <br>
      <br>
      <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>
      Cargando
    </div>

  </div>
</template>

<script>

import ShieldService from "../services/shield.service";
import RutUtils from "../utils/rut.utils";

import Auto from "@/assets/images/VehiculoDatos.png";
import Propietario from "@/assets/images/PropietarioDatos.png";

import Vue from "vue";
import swal from 'sweetalert';
// import keygen from 'keygen';

import CryptoJS from 'crypto-js';

import { VueReCaptcha } from 'vue-recaptcha-v3'
const { VUE_APP_RECAPTCHA3_SITE_KEY } = process.env;

Vue.use(VueReCaptcha, { siteKey: VUE_APP_RECAPTCHA3_SITE_KEY })

export default {
  name: "InitForm",
  props: ["carData", "propietarioData", "planData", "servicesData", "arrayShoppingCart"],
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,

    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    loadCarCreate: false,
    campoMotorNull: false,
    labelsSup: null,
    labelName: null,
    rectangulo2: null,
    boxValue: null,
    priceBox: null,
    btnContinue: null,
    formBox: null,
    box: null,
    termsConditionsTitle: null,
    btnClose: null,
    Auto,
    Propietario,
    newRut: '',
    keygen: null,
    typeCliente: null,
    typeClienteRuta: null,
    dialog: false,
    recuadro: null,
    screen: window.screen.width,
    marcaVehicle: [],
    modeloVehicle: [],
    planId: null,
    rutRules: [
      (v) => !!v || "El rut es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no valido",
    ],
    emailRules: [
      (v) => !!v || "El correo electrónico es requerido",
      (v) => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(v) || "Ingrese un correo válido",
    ],
    patenteRules: [
      (v) => !!v || "El número de patente es requerido",
      (v) => (v && v.length <= 6) || 'La patente debe tener máximo 6 caracteres',
      (v) => (v && v.length > 4) || 'La patente debe tener mínimo 5 caracteres',
      (v) => /^[^\s]+$/.test(v) || 'La patente no puede contener espacios',
      (v) => /^[A-Za-z0-9]+$/.test(v) || 'La patente solo puede contener letras y números',
    ],
    yearRules: [
      (v) => !!v || "El año del vehículo es requerido",
    ],
    typeRules: [
      (v) => !!v || "El tipo de vehículo es requerido",
    ],
    marcaRules: [
      (v) => !!v || "La marca del vehículo es requerida",
    ],
    modeloRules: [
      (v) => !!v || "El modelo de vehículo es requerido",
    ],
    motorRules: [
      (v) => !!v || "El número del motor es requerido",
    ],
    namesRules: [
      (v) => !!v || "Debe ingresar su nombre",
    ],
    surnamePRules: [
      (v) => !!v || "Debe ingresar su apellido paterno",
    ],
    surnameMRules: [
      (v) => !!v || "Debe ingresar su apellido materno",
    ],
    phoneRules: [
      (v) => !!v || "El teléfono es requerido",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
      (v) => (!!v && v.length == 8) || "Ingrese un número válido",
    ],
    conditionsRules: [
      (v) => !!v || "Debe estar de acuerdo con los términos y condiciones",
    ],
  }),
  methods: {
    emailValidate() {
      this.propietarioData.email = this.propietarioData.email.replace(/\s+/g, '');
    },
    async validate() {
      const continuar_2 = document.getElementById('continuar_2');

      // // reCaptcha v3 token
      // await this.$recaptchaLoaded();
      // const token = await this.$recaptcha('createSoap');

      if (this.$refs.form.validate()) {

        this.loadCarCreate = true;

        // var CarRes;

        let verifyPatente = this.arrayShoppingCart.find(item => item.carData.patente === this.carData.patente);

        if (verifyPatente) {
          swal({
            title: "¡Patente duplicada!",
            text: "Ya ha ingresado esta patente al carrito de compra",
            icon: "info",
            button: "Ok",
          });
          this.servicesData.loadSoap = false;
        } else {

          try {

            // reCaptcha v3 token
            await this.$recaptchaLoaded();
            const tokenConsulta = await this.$recaptcha('consultaSoap');

            var consultaCarSoap = await ShieldService.createSoapConsulta(this.propietarioData, this.carData, tokenConsulta);

            if (consultaCarSoap.status == 'ok') {

              this.arrayShoppingCart.push({ carData: this.carData, propietarioData: this.propietarioData });

              this.servicesData.priceTotal = this.arrayShoppingCart.reduce((accumulator, item) => accumulator + (item.carData.price || 0), 0);

              const arrayShoppingCartLocal = JSON.stringify(this.arrayShoppingCart);
              const encryptedArrayShoppingCartLocal = CryptoJS.AES.encrypt(arrayShoppingCartLocal, this.secretKey).toString();
              localStorage.setItem('arrayShoppingCart', encryptedArrayShoppingCartLocal);

              const carDataLocal = JSON.stringify(this.carData);
              const encryptedCarDataLocal = CryptoJS.AES.encrypt(carDataLocal, this.secretKey).toString();
              localStorage.setItem('carData', encryptedCarDataLocal);

              const propietarioDataLocal = JSON.stringify(this.propietarioData);
              const encryptedPropietarioDataLocal = CryptoJS.AES.encrypt(propietarioDataLocal, this.secretKey).toString();
              localStorage.setItem('propietarioData', encryptedPropietarioDataLocal);

              this.servicesData.e1 = 2;

              const servicesDataLocal = JSON.stringify(this.servicesData);
              const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
              localStorage.setItem('servicesData', encryptedServicesDataLocal);

              this.$router.push({ path: `/${this.servicesData.typeCliente}/resumen` });
              
              this.loadCarCreate = false;
            } else {
              swal({
                title: "¡Ocurrió un problema!",
                text: "Inténtelo nuevamente.",
                icon: "error",
                button: "Ok",
              }).then(() => {
                this.$router.push({ path: `/${this.servicesData.typeCliente}/inicio` });
              });
            }

          } catch (error) {
            console.log(error);
          }

        }

      }
    },
    changeRut(rut) {
      this.propietarioData.rut = RutUtils.formatRut(rut);
      this.newRut = RutUtils.formatRut(rut);
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
  },
  mounted() {
    if (this.carData.motor == null || this.carData.motor.length < 2) {
      this.campoMotorNull = true;
    }

    this.changeRut(this.propietarioData.rut);

    if (this.screen > 800) {
      this.recuadro = 'recuadro';
      this.labelsSup = 'labelsSup';
      this.labelName = 'labelName';
      this.rectangulo2 = 'rectangulo2';
      this.boxValue = 'boxValue';
      this.priceBox = 'priceBox';
      this.btnContinue = 'btnContinue';
      this.formBox = 'formBox';
      this.box = 'box';
      this.termsConditionsTitle = 'termsConditionsTitle';
      this.btnClose = 'btnClose';

    } else if (this.screen <= 800) {
      this.labelsSup = 'labelsSup2';
      this.labelsInf = 'labelsInf2';
      this.columnas = 'columnas2';
      this.recuadro = '';
      this.labelName = 'labelName2';
      this.rectangulo2 = 'rectangulo2Mobile';
      this.boxValue = 'boxValue2';
      this.priceBox = 'priceBox2';
      this.btnContinue = 'btnContinue2';
      this.formBox = 'formBox2';
      this.box = 'box2';
      this.termsConditionsTitle = 'termsConditionsTitle2';
      this.btnClose = 'btnClose2';

    }
  }
};
</script>

<style lang="css" scoped>
.titulo2 {
  text-align: left;
  font: normal normal medium 20px/25px Arial;
  letter-spacing: 0.3px;
  color: #0069B6;
  text-transform: uppercase;
  opacity: 1;
}

.subtitulo2Box {
  margin-left: 5%;
  width: 90%;
  margin-bottom: -7%;
}

.subtitulo2Box2 {
  margin-left: 5%;
  width: 89%;
  margin-bottom: -7.1%;
}

.subtitulo2MobileBox {
  /* margin-left: 5%; */
  width: 100%;
  margin-top: -1%;
  margin-bottom: -11%;
  text-align: center;
}

.subtitulo2 {
  text-align: left;
  font: normal normal normal 15px/17px Arial;
  letter-spacing: 0.23px;
  color: #333;
  opacity: 1;
}

.subtitulo3 {
  /* text-align: center; */
  font: normal normal normal 15px/17px Arial;
  letter-spacing: 0.23px;
  color: #333;
  opacity: 1;
}

.labelName {
  text-align: left;
  font: Arial;
  font-size: 13px;
  letter-spacing: 0px;
  color: #333;
  opacity: 1;
  font-weight: bold;
  margin-left: 4%;
}

.labelName2 {
  text-align: left;
  font: Arial;
  font-size: 13px;
  letter-spacing: 0px;
  color: #333;
  opacity: 1;
  font-weight: bold;
  margin-left: 21% !important;
}

.labelNameMobile {
  text-align: left;
  font: Arial;
  font-size: 13px;
  letter-spacing: 0px;
  color: #333;
  opacity: 1;
  font-weight: bold;
  margin-left: 7%;
}

.valor {
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  letter-spacing: 0.18px;
  color: #FFF;
  opacity: 1;
  font-size: 20px;
  font-weight: bold;
  margin-top: -3%;

}

.boxValue {
  background-color: #4F74E3;
  height: 20%;
  margin-top: -5%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.boxValue2 {
  background-color: #4F74E3;
  height: 20%;
  margin-top: -7.5%;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}

.precio {
  text-align: center;
  font: Arial;
  font-size: 30px !important;
  letter-spacing: 0.38px;
  color: #2D387C;
  text-transform: uppercase;
  opacity: 1;
  font-size: 25px;
}

.textTerminos {
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  letter-spacing: 0px;
  color: #333;
}

.terminosCondiciones {
  text-align: center;
  text-decoration: underline;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  letter-spacing: 0px;
  color: #4F74E3;
  font-size: 15px;
  text-decoration: underline;
}

.labelsSup {
  width: 200px;
  height: 58px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  opacity: 1;
  margin-left: 4%;
}

.labelsSup2 {
  width: 85%;
  height: 49.9px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  opacity: 1;
  margin-left: 7.5%;
  margin-bottom: 5%;
}

.labelsInf {
  width: 200px;
  height: 58px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  opacity: 1;
  margin-left: 21%;
}

.labelsInf2 {
  width: 100%;
  height: 58px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  opacity: 1;
}

.rectangulo {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  opacity: 1;
  margin-left: 1%;
  margin-right: 1%;
  max-width: 500px;
}

.rectangulo2 {
  max-width: 520px;
  height: 250px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  opacity: 1;
  margin: auto;
}

.rectangulo2Mobile {
  max-width: 482px;
  width: 93.5%;
  height: 280px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 6px #00000029;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  opacity: 1;
  margin-left: 4%;
  margin-top: 5%;
}

.columnas {
  display: flex;
}

.columnaFinal {
  margin-bottom: 1%;
}

.columnas2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: center;
}

.recuadro {
  justify-content: center;
  display: flex;
}

/* .test {
  text-align: left;
  font-family: "Scotia_Bd";
  letter-spacing: 0.3px;
  color: #2D387C;
  text-transform: uppercase;
  opacity: 1;
}

.test2 {
  text-align: left;
  font-family: "Scotia_BdIt";
  letter-spacing: 0.3px;
  color: #2D387C;
  text-transform: uppercase;
  opacity: 1;
}

.test3 {
  text-align: left;
  font-family: "Scotia_Headline";
  letter-spacing: 0.3px;
  color: #2D387C;
  text-transform: uppercase;
  opacity: 1;
}

.test4 {
  text-align: left;
  font-family: "Scotia_It";
  letter-spacing: 0.3px;
  color: #2D387C;
  text-transform: uppercase;
  opacity: 1;
}

.test5 {
  text-align: left;
  font: Arial;
  letter-spacing: 0.3px;
  color: #2D387C;
  text-transform: uppercase;
  opacity: 1;
}


.test6 {
  text-align: left;
  font-family: "Scotia_Lt";
  letter-spacing: 0.3px;
  color: #2D387C;
  text-transform: uppercase;
  opacity: 1;
}

.test7 {
  text-align: left;
  font-family: "Scotia_LtIt";
  letter-spacing: 0.3px;
  color: #2D387C;
  text-transform: uppercase;
  opacity: 1;
}

.test8 {
  text-align: left;
  font-family: "Scotia_Rg";
  letter-spacing: 0.3px;
  color: #2D387C;
  text-transform: uppercase;
  opacity: 1;
} */
.btnContinue {
  margin-top: 2%;
  width: 300px;
  height: 50px !important;
  background: #4F74E3 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  opacity: 1;
  text-align: center;
  font: normal normal normal 18px/27px Arial;
  letter-spacing: 0.66px;
  color: white;
  text-transform: uppercase;
  opacity: 1;
  font-weight: bold;
}

.btnContinue2 {
  margin-top: 4%;
  width: 250px;
  height: 50px !important;
  background: #4F74E3 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  opacity: 1;
  text-align: center;
  font: normal normal normal 18px/27px Arial;
  letter-spacing: 0.66px;
  color: white;
  text-transform: uppercase;
  opacity: 1;
  font-weight: bold;
}

.hrStyle {
  width: 147px;
  height: 0px;
  border: 1px solid #D0D0D0;
  opacity: 1;
  margin: auto;
}

.titles {
  background-color: #EFEFEF;
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  width: 105%;
  margin-left: -2.5%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 3%;
  padding-bottom: 3%;
  margin-top: -2.7%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.titlesMobile {
  background-color: #EFEFEF;
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  width: 108.1%;
  margin-left: -4.1%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 3%;
  padding-bottom: 3%;
  margin-top: -4.2%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title1 {
  padding-left: 3%;
  color: #2D387C;
}

.title2 {
  margin-left: 1%;
  color: #4F74E3;
}

.title3 {
  margin-left: 2%;
  color: #4F74E3;
}

.space {
  margin-bottom: 10%;
}

.priceBox {
  display: flex;
}

.priceBox2 {
  display: flex;
  max-width: 482px;
  width: 90%;
  margin-left: 4%;
}

.formBox {
  height: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
}

.formBox2 {
  height: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
}


.termsConditionsTitle {
  font-size: 24px;
}

.termsConditionsTitle2 {
  font-size: 20px;
}

.btnClose {
  background-color: #4F74E3;
  color: white;
  margin-left: 80%;
  border-radius: 10px;
  width: 80px;
  margin-bottom: 1%;
}

.btnClose2 {
  background-color: #4F74E3;
  color: white;
  margin-left: 74%;
  border-radius: 10px;
  width: 80px;
  margin-bottom: 1%;

}
</style>