<template>
  <v-container
    style="position: relative; width: 100%; justify-content: center; height: 100%; background: #FFFFFF 0% 0% no-repeat padding-box; opacity: 1;">

    <div class="text-center">

      <br>
      <div class="rectangulo">
        <div class="valor">
          <b> Valor total a pagar</b>
        </div>
        <br>

        <div :class="priceBox">
          <hr class="hrStyle">
          <b class="precio">{{ formatPrice(servicesData.priceTotal) }}</b>
          <hr class="hrStyle">
        </div>
        <br>

      </div>

      <div v-if="(servicesData.typeCliente == 'clientes') && (arrayShoppingCart.length == 1)">

        <br>

      </div>

      <br>
      <br>

      <template>
        <v-data-table :footer-props="{ 'items-per-page-text': '' }" :headers="headers" :items="arrayShoppingCart"
          :items-per-page="isMobile ? 1 : 5" class="elevation-1 table" no-data-text="CARRITO VACÍO ¡AÑADE TU SOAP!">
          <template v-slot:top>
            <v-dialog v-if="screen > 800" v-model="dialogDelete" max-width="600px">
              <v-card>

                <v-card-title class="text-h5">
                  <div class="titleBox">
                    <b class="title1">Estás seguro de </b>
                    <b class="title2">eliminar</b>
                    <b class="title1">este producto?</b>
                  </div>
                </v-card-title>

                <v-card-actions>

                  <v-spacer></v-spacer>
                  <v-btn class="btnClosePopUp" text @click="closeDelete">Cancelar</v-btn>
                  <v-btn class="btnConfirmPopUp" id="quitarOtro_3" text @click="deleteItemConfirm">Confirmar</v-btn>
                  <div class="space"></div>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-else v-model="dialogDelete" max-width="360px">
              <v-card>

                <v-card-title class="text-h5">
                  <div class="titleBoxMobile">
                    <b class="title1">¿Estás seguro de <br><span style="color:#4F74E3">eliminar</span> este
                      producto?</b>
                  </div>
                </v-card-title>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <div class="btnBoxMobile">
                    <v-btn class="btnClosePopUp2" text @click="closeDelete">Cancelar</v-btn>
                    <br><br>
                    <v-btn class="btnConfirmPopUp2" id="quitarOtro_3" text @click="deleteItemConfirm">Confirmar</v-btn>
                    <div class="space"></div>
                  </div>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2 trash" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:item.price="{ item }">
            <label style="margin:auto">{{ formatPrice(item.price) }}</label>
          </template>

        </v-data-table>

      </template>

      <br>
      <br>

      <div v-if="pagar != true" class="finalBox">
        <b class="question">¿QUIERES AGREGAR OTRO VEHÍCULO?</b>
        <br v-if="screen <= 800">
        <br v-if="screen <= 800">
        <v-btn class="button1" id="agregarOtro_3" @click="NewCarro()">Agregar otro </v-btn>
        <br v-if="screen <= 800">
        <br v-if="screen <= 800">
        <v-btn v-if="screen > 800" class="button2" @click="obtenerToken()" id="confirmar_3">Continuar</v-btn>

        <v-btn v-if="screen <= 800" class="button2" style="margin-top: 5%" @click="obtenerToken()"
          id="confirmar_3">Continuar
          Pago</v-btn>
      </div>

      <v-form ref="form" v-model="valid">

        <v-col v-if="pagar != false" style="max-width: 320px; margin:auto;">
          <b class="title"> INGRESE RUT PAGADOR</b>
          <br>
          <br>
          <label class="labelName"><b>Ingrese el RUT</b></label>
          <v-text-field v-model="newRut" :rules="rutRules" label="Ingrese RUT de quien paga" required solo
            :class="patentFieldView" hide-details="auto" @change="changeRut">
          </v-text-field>
        </v-col>
        <br>
        <v-col v-if="pagar != false">
          <div class="checkbox">
            <v-checkbox style="margin-top:2.5%" v-model="conditions" :rules="conditionsRules" required dense
              @change="sendParams()"></v-checkbox>
            <label>
              <b>AUTORIZACIÓN PARA TRATAMIENTO DE DATOS PERSONALES:</b> <br> Autorizo que mi información personal sea
              recopilada, almacenada, tratada y divulgada tanto por Scotiabank como por la Compañía de Seguros, según la
              normativa vigente y conforme con el Acuerdo de Privacidad contenido en www.scotiabankchile.cl y las
              políticas propias de la compañía aseguradora.
            </label>

          </div>
          <br v-if="screen >= 800">
        </v-col>
      </v-form>

    </div>
  </v-container>
</template>

<script>
import RutUtils from "../utils/rut.utils";
// import ShieldService from "../services/shield.service";

import CryptoJS from 'crypto-js';
import swal from 'sweetalert';
import Vue from "vue";

import { VueReCaptcha } from 'vue-recaptcha-v3'
const { VUE_APP_RECAPTCHA3_SITE_KEY } = process.env;

Vue.use(VueReCaptcha, { siteKey: VUE_APP_RECAPTCHA3_SITE_KEY })

export default {

  name: "PlanForm",
  props: ["carData", "propietarioData", "planData", "servicesData", "arrayShoppingCart"],
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,

    typePlan: null,
    isMobile: false,
    status: false,
    screen: window.screen.width,
    typeCliente: null,
    keygen: null,
    priceTotal: 0,
    priceBox: null,

    patentFieldView: null,
    planId: null,
    type: null,
    token: null,
    pagar: false,
    conditions: null,
    dialog: false,
    valid: false,
    switchFormaPago: null,
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    webPayUrl: process.env.VUE_APP_WEBPAY_URL,
    newRut: "",
    dialogDelete: false,
    dialog: false,
    editedIndex: -1,
    currentPage: 1,
    itemsPerPage: 5,
    editedItem: {
      patenteNumber: '',
      typeVehicle: '',
      modelVehicle: '',
      personEmail: '',
      price: 0,
    },
    defaultItem: {
      patenteNumber: '',
      typeVehicle: '',
      modelVehicle: '',
      personEmail: '',
      price: 0,
    },
    headers: [
      {
        text: 'Patente',
        align: 'start',
        sortable: false,
        value: 'carData.patente',
        align: 'center',
      },
      { text: 'Tipo de Vehículo', value: 'carData.type', align: 'center', sortable: false },
      { text: 'Modelo', value: 'carData.model', align: 'center', sortable: false },
      { text: 'Correo', value: 'propietarioData.email', align: 'center', sortable: false },
      { text: 'Valor SOAP', value: 'carData.price', align: 'center', sortable: false },
      { text: 'Eliminar', value: 'actions', sortable: false, align: 'center' },
    ],
    confirmRules: [(v) => !!v || "Selecciona una opción"],
    rutRules: [
      (v) => !!v || "Rut es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no válido",
    ],
    rutRules2: [
      (v) => !!v || "Rut del cliente es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no válido",
    ],
    contrasenaRules: [
      (v) => !!v || "La contraseña es requerida",
    ],
    conditionsRules: [
      (v) => !!v || "",
    ]
  }),
  async mounted() {
    this.servicesData.propietario = 'true';
    this.pagar = false;

    // const query = this.$route.query;

    if (this.screen > 800) {
      this.patentFieldView = 'patentFieldView';
      this.priceBox = 'priceBox';
    } else if (this.screen <= 800) {
      this.patentFieldView = 'patentFieldView2';
      this.priceBox = 'priceBox2';
    }

  },
  methods: {
    async obtenerToken() {
      this.pagar = true;
    },
    deleteItem(item) {
      this.editedIndex = this.arrayShoppingCart.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {

      this.arrayShoppingCart.splice(this.editedIndex, 1);

      this.servicesData.priceTotal = this.arrayShoppingCart.reduce((accumulator, item) => accumulator + (item.carData.price || 0), 0);

      const arrayShoppingCartLocal = JSON.stringify(this.arrayShoppingCart);
      const encryptedArrayShoppingCartLocal = CryptoJS.AES.encrypt(arrayShoppingCartLocal, this.secretKey).toString();
      localStorage.setItem('arrayShoppingCart', encryptedArrayShoppingCartLocal);

      const servicesDataLocal = JSON.stringify(this.servicesData);
      const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
      localStorage.setItem('servicesData', encryptedServicesDataLocal);

      if (this.arrayShoppingCart.length == 0) {
        swal({
          title: "¡No posee SOAP's en su carrito!",
          text: "Será redirigido al inicio de la plataforma",
          icon: "info",
          button: "Ok",
        }).then(() => {
          this.$router.push({ path: `/${this.servicesData.typeCliente}/inicio` });
        });
      } else {
        this.closeDelete();
        // location.reload();  
      }

    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      })
    },
    back() {
      this.$emit("back");
    },
    validate(plan) {
      this.$emit("next-step", 2, plan);
    },
    NewCarro() {
      if (this.arrayShoppingCart.length >= 20) {
        swal({
          title: "¡Límite alcanzado!",
          text: "No puedes agregar más elementos al carrito.",
          icon: "info",
          button: "Ok",
        });
        return; // Salir de la función si se alcanza el límite
      } else {
        const agregarOtro_3 = document.getElementById('agregarOtro_3');
        // this.token = null;

        localStorage.removeItem('propietarioData');
        localStorage.removeItem('carData');

        this.$router.push({ path: `/${this.servicesData.typeCliente}/inicio` });
      }
    },
    sendParams() {
      var params = {
        // token: this.token,
        conditions: this.conditions,
      };
      this.$emit("getToken", params);
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    async changeRut(rut) {
      this.servicesData.rutPropietario = RutUtils.formatRut(rut);
      this.newRut = RutUtils.formatRut(rut);
    },
  },
};
</script>


<style lang="css" scoped>
.titulo {
  color: rgb(126, 43, 137);
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0px;
  font-family: Nunito;
  text-align: center;
  line-height: 1.2;
}

.subtitulo {
  font-family: Nunito;
  width: 280px;
  text-align: center;
  margin: 15px auto auto;
  line-height: 1.2;
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.imageCenco {
  background-image: url("~@/assets/images/CencoImage.png");
  background-position: center;
  background-size: cover;
  display: flex;
}

.login {
  display: inline-block;
  box-sizing: border-box;
  border-radius: 0.3rem;
  background-color: rgb(19, 65, 105);
  height: 40px;
  line-height: 40px;
  width: 100%;
  padding: 0px 1.5rem;
  cursor: pointer;
  color: white;
  font-weight: 600;
  margin: auto;
}

#app>div.v-dialog__content.v-dialog__content--active>div>div>div>div>div:nth-child(1)>button {
  height: 36px;
  min-width: 64px;
  padding: 0 16px;
  background-color: rgb(19, 65, 105);
}

.precio {
  height: 40px;
  text-align: center;
  font: Arial;
  font-size: 30px;
  letter-spacing: 0.53px;
  color: #2D387C;
  opacity: 1;
}

.patenteNumber {
  width: 138px;
  height: 40px;
  text-align: center;
  font: Arial;
  font-size: 35px;
  letter-spacing: 0.53px;
  color: #4E4E4E;
  text-transform: uppercase;
  opacity: 1;
}

.valor {
  background-color: #4F74E3;
  color: #FFF;
  font-family: 'Helvetica';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.24px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 2%;
  padding-bottom: 2%;
}

.condicion {
  width: 56px;
  height: 21px;
  text-align: center;
  font: Arial;
  font-size: 14px;
  letter-spacing: 0.26px;
  color: #0069B6;
  text-transform: uppercase;
  opacity: 1;
}

.patente {
  width: 76px;
  height: 21px;
  font: Arial;
  font-size: 17px;
  letter-spacing: 0.26px;
  color: #4E4E4E;
  text-transform: uppercase;
  opacity: 1;
}

.webpay {
  width: 80%;
  height: 206px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  opacity: 1;
  margin: auto;
}

.cenco {
  width: 80%;
  height: 206px;
  background: #753783 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  opacity: 1;
  margin: auto;
}

.rectangulo {
  box-shadow: 0px 0px 6px #00000029;
  max-width: 420px;
  opacity: 1;
  margin: auto;
  border-radius: 10px;

}

.hrStyle {
  width: 147px;
  height: 0px;
  border: 1px solid #D0D0D0;
  opacity: 1;
  margin: auto;
}

.question {
  color: #2D387C;
  font-family: 'Inter', sans-serif;
  margin-right: 1%;
  font-size: 20px;
}

.button1 {
  width: 270px;
  height: 50px !important;
  background-color: #2D387C !important;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font: normal normal normal 20px Arial;
  letter-spacing: 0.66px;
  color: white;
  opacity: 1;
  margin-left: 1%;
  font-weight: bold;
}

.button2 {
  width: 270px;
  height: 50px !important;
  background-color: #4F74E3 !important;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font: normal normal normal 20px Arial;
  letter-spacing: 0.66px;
  color: white;
  opacity: 1;
  margin-left: 1%;
  font-weight: bold;

}

.finalBox {
  margin-left: -4%;
}

.title {
  color: #2D387C;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.36px !important;
}

.labelName {
  text-align: left;
  font: Arial;
  font-size: 12px;
  letter-spacing: 0px;
  color: #333;
  opacity: 1;
  font-weight: bold;
  margin-left: -72%;
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.2px;
  line-height: 38.5px;
}

.checkbox {
  max-width: 540px;
  margin: auto;
  color: #333;
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.2px;
  display: flex;
  text-align: justify;
  font-size: 13px;
  margin-bottom: -3%;
}

.payTitleImage {
  color: #2D387C;
  display: flex;
  justify-content: center;
}

.payButtons {
  display: flex;
  justify-content: center;
}

.pay {
  background-color: #EFEFEF;
  /* margin-left: -20%; */
  /* max-width: x100%; */
  width: 100vw;
  position: absolute;
  left: 50%;
  margin-left: -200px;
}

.payTitle {
  font-size: 24px;
  margin-top: 0.5%;
}

.payImage {
  margin-left: 2%;
}

.btnBack {
  width: 270px;
  height: 50px !important;
  background-color: #2D387C !important;
  border-radius: 8px !important;
  opacity: 1;
  text-align: center;
  font: normal normal normal 20px Arial;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;

  font-weight: bold;
}

.btnPay {
  width: 260px;
  height: 50px !important;
  background-color: #4F74E3 !important;
  border-radius: 8px !important;
  opacity: 1;
  text-align: center;
  font: normal normal normal 20px Arial;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  margin-left: 6%;
  font-weight: bold;
}

.tableHeaders {
  background-color: #2D387C;
  color: white !important;
  border: 2px solid #2D387C;
}

.text-center {
  text-align: center;
}

.table {
  border: 2px solid #2D387C;
  margin-top: -2%;
  margin-bottom: -1%;
}

.btnClosePopUp {
  background-color: #2D387C;
  color: #FFF;
  border-radius: 8px;
  font-weight: bold;
  letter-spacing: 0.8px;
  width: 30%;
  margin-top: -2%;
}

.btnClosePopUp2 {
  height: 40px !important;
  background-color: #2D387C;
  color: #FFF;
  border-radius: 8px;
  font-weight: bold;
  letter-spacing: 0.8px;
  width: 100%;
  margin-top: -2%;
}

.btnConfirmPopUp {
  background-color: #4F74E3;
  color: #FFF;
  border-radius: 8px;
  font-weight: bold;
  letter-spacing: 0.8px;
  width: 30%;
  margin-top: -2%;
}

.btnConfirmPopUp2 {
  height: 40px !important;
  background-color: #4F74E3;
  color: #FFF;
  border-radius: 8px;
  font-weight: bold;
  letter-spacing: 0.8px;
  width: 100%;
  margin-top: -2%;
}

.title1 {
  color: #2D387C;
  font-size: 22px;
}

.title2 {
  color: #4F74E3;
  margin-left: 1%;
  margin-right: 1%;
}

.titleBox {
  width: 100%;
  text-align: center;
}

.titleBoxMobile {
  width: 90%;
  margin: auto;
  text-align: center;
  margin-top: 1%;
  margin-bottom: 3%;
}

.btnBoxMobile {
  display: block;
  width: 70%;
}


.patentFieldView {
  width: 300px;
  height: 50px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin-left: auto;
  text-transform: uppercase;

}

.patentFieldView2 {
  width: 100%;
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  /* margin-left: 41%; */
  text-transform: uppercase;
}

.priceBox {
  display: flex;
  margin-top: -2%;
}

.priceBox2 {
  display: flex;
  margin-top: -2%;
  width: 90%;
  margin-left: 4%;
}
</style>

<style lang="css">
.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
  background-color: #2D387C;
  color: white !important;
}

.space {
  margin-bottom: 10% !important;
}

.trash {
  color: red !important;
}
</style>